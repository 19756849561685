import styled from 'styled-components'

const CategoryCard = styled.div`
  width:100%;
  display: flex;
  border-radius:6px;
  border: ${({ active }) => active ? "2px solid rgba(0, 92, 135, 1)" : "none"};
  cursor:pointer;
  justify-content: center;
  align-items: center;
  background: ${({background})=> background ? background : ""};
  box-shadow: ${({shadow_color, active}) => shadow_color && active ? `0px 8px 10px 3px ${shadow_color}` : '' };
  .categoryImage{
    width:40%;
    height:30px;
    display:flex;
    margin:auto;
    justify-content: right;
    align-items: center;
    border-radius:6px;
    img{
      height:30px;  
      width:30px;
      margin-right: 10px;
    }
  }
  .categoryName{
    width: calc(100% - 60px);
    font-family: ${ ({active}) => active ? "Rubik-medium" : "Rubik-regular" };
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ color, active }) => active ? color : 'rgba(0, 92, 135, 0.6)'};
    text-align: left;
    line-height: 24px;
    padding-left: 20px;
  }
`;

const CategoryCardWrapper = styled.div`
  width: 195px;
  height: 80px;
  border-radius:6px;
  background: ${({ background, active }) => active && background ? background : 'white'};
  display:flex;
`;

const AssesmentWrapper = styled.div`
width: 100%;
height:100%;
display:flex;
.reportCard{
  width: 24.1%;
}
.questionCard{
  width: calc(75.9% - 25px);
  display:block;
  margin-left: 25px;
}
`;

const InsightReport = styled.div`
width: 100%;

border-radius:6px;
background:white;
.header{
  background:#005C87;
  display:flex;
  border-radius:6px 6px 0 0;
  font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color:white;
align-items:center;
text-align:center;
height:50px;
justify-content: center;
}
.body{
  width:100%;
  display:block;
  padding: 0px 15px;
background:white;
.cardNoBorder{
  padding:15px 0px;
  display: flex;
  .img{
    width:50px;
    height:50px;
    >img{
      width:50px;
      height:50px;
      border-radius:3px;
    }
  }
  .name{
    font-family: Rubik;
    font-size: 14px;
font-weight: 400;
line-height: 24px;
color:#005C87;
margin-left: 15px;
display:flex;
align-items:center;
  }
  .score{
    font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: #005C87;
display:flex;
margin:auto;
margin-right:0px;
  }
}
.card{
  padding:15px 0px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.2);
  display: flex;
  .img{
    width:50px;
    height:50px;
    >img{
      width:50px;
      height:50px;
      border-radius:3px;
    }
  }
  .name{
    font-family: Rubik;
    font-size: 14px;
font-weight: 400;
line-height: 24px;
color:#005C87;
margin-left: 15px;
display:flex;
align-items:center;
  }
  .score{
    font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: #005C87;
display:flex;
margin:auto;
margin-right:0px;
  }
}
}

.card-style{
  padding:15px 0px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.2);
  display: flex;
  .img{
    width:50px;
    height:50px;
    >img{
      width:50px;
      height:50px;
      border-radius:3px;
    }
  }
  .name{
    font-family: Rubik;
    font-size: 14px;
font-weight: 400;
line-height: 24px;
color:rgba(0, 0, 0, 1);
margin-left: 15px;
display:flex;
align-items:center;
  }
  .score{
    font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: rgba(0, 47, 71, 1);
display:flex;
margin:auto;
margin-right:0px;
  }
}
}
`;

const CategoryScoreCard = styled.div`
width: 100%;
margin-top: 15px;
display:block;
background:white;
padding:15px;
.categoryDetails{
width: 100%;

display:flex;
.image{
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({imageBackgroundColor})=> imageBackgroundColor && imageBackgroundColor};
  >img{
    width:30px;
    height:30px;
  }
}
.name{
  font-family: Rubik;
font-size: 16px;
font-weight: 400;
line-height: 20px;
color: #005C87;
display:flex;
align-items:center;
height: 60px;
padding: 0 0 10px 15px;
}
}
.score{
  width:100%;
  display:flex;
  justify-content: space-between;

  .value{
    font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: rgba(253, 113, 117, 1);
color: ${({ color }) => color ? color : 'rgba(157, 135, 169, 1)'};
margin-top:5px;
  }
  }

`;

const QuestionsCard = styled.div`
width: 100%;
background:white;
${'' /* margin-bottom:25px; */}
margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '25px'};
border-radius:6px;
.question{
  padding:15px;
  font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color:#005C87;
border-bottom: 1px solid rgba(0, 92, 135, 0.6);
}
.body{
  display:flex;
  width:100%;
  .circularPercentage{
   width: ${({ width }) => width ? "0%" : '32.6%'};
   background: rgba(0, 92, 135, 0.05);
   display:flex;
  }
  .answers{
    width: ${({ width, widthValue }) =>widthValue?widthValue: width ? "100%" : '67.4%'};
    display: ${({ type }) =>type?  "flex" : ''};
    margin: ${({ type }) =>type?"auto" : ''};
    div > .line > .borderLine {
      border-top: 1px solid rgba(0, 92, 135, 0.6);
      .value{
        color: rgba(0, 92, 135, 0.6);
      }
    }
    .circularGraphPercent{
      background: rgba(0, 92, 135, 0.05);
      padding: 25px 60px;
      width:100%;
      .value{
        font-family: Rubik-medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color:#005C87;
        margin-top:10px;
      } 
      .average{
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color:#005C87;
        padding-left:5px; 
        margin-top:10px;
      }
    }
  }
}
`;

const AnswerWrapper = styled.div`
width: 100%;
display: ${({ display }) => display ? display : 'block'};
flex-wrap: ${({ wrap }) => wrap ? wrap : ''};
padding: ${({ padding }) => padding ? padding : '13px 35px 37px 37px'};
.bargraph{
  height:300px
}
.circleWrapper{
  display:flex;
  width:100%;
  justify-content: ${({ flex }) => flex ? flex : ''};
}
.line{
  display: flex;
  .fill{
    width:125px;
  }
  .borderLine{
    width: calc(100% - 80px);
    border-top: 1px solid rgba(156, 156, 156, 1);
    display: flex;
    justify-content: space-between;
    .value{
      padding-top:10px;
      font-family: Rubik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(156, 156, 156, 1);
    }
  }
}
`;

const AnswerCard = styled.div`
.wrapper{
  width:100%;
  display: ${({ display }) => display ? display : 'block'};
  padding-bottom: ${({ margin }) => margin ? margin : '0px'};
  .optionValue{
    width:80px;
    display:flex;
    align-items: center;
    font-family: Rubik-regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color:#005C87;
    text-align: end;
    justify-content: flex-end;
    text-transform: capitalize;
  }
  .progressGraph{
    width: calc(100% - 80px);
    margin-left: 25px;
    border-top: ${({ border }) => border ? "1px solid rgba(0, 92, 135, 0.05)" : 'none'};
    padding-top:10px;
  }
}
.answerCard{
  width:100%;
  display:block;
  margin-top:22px;
  .answerDataValue{
    width:100%;
    margin-bottom:5px;
    display:flex;
    .first{
      display:flex;
      .optionName{
        font-family: Rubik;
font-size: 14px;
line-height: 24px;
color: rgba(0, 47, 71, 1);
margin-right:10px;
text-transform: capitalize;
      }
      .up{
        font-family: Rubik;
font-size: 12px;
font-weight: 400;
line-height: 24px;
color: rgba(159, 201, 137, 1);
margin-right:5px;
      }
      .down{
        font-family: Rubik;
font-size: 12px;
font-weight: 400;
line-height: 24px;
color: rgba(253, 113, 117, 1);
margin-right: 3px;
      }
      .arrow{
        width: 15px;
    height: 15px;
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    display: flex;
    >img{
      width: 15px;
      height: 15px;
    }
      }
    } 
    .second{
      font-family: Rubik-Medium;
      font-size: 16px;
      margin: auto;
      margin-right: 0px;
      line-height:24px;
      color: rgba(157, 135, 169, 1);
      color: ${({ color }) => color ? color : 'rgba(157, 135, 169, 1)'};
    }
  }
}

`;

const CircleNew = styled.div`
  width: ${({ width }) => width ? width : '136px'};
  height: ${({ height }) => height ? height : '136px'};
  border-radius: 50%;
padding: 10px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
`;

const CircularGraph = styled.div`
width: ${({ width }) => width ? width : '100%'};
cursor:pointer;
.hidePercentCard{
    width: 50px;
    height: 50px;
  .showPercent{
    top: ${({circleWidth})=> circleWidth > 130 ? "15px" : "40px" };
    left: ${({circleWidth})=> circleWidth > 130 ? "15px" :"-10px" };
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: 'Rubik-medium';
    font-size: 16px;
    color:  ${({circleWidth})=> circleWidth > 130 ? "#FFFFFF" :"#005C87" };
  }
}


.circleContainer{
  border-radius:50%;
  width:105px;
  margin: auto;
  background: ${({ backgroundColor}) =>  backgroundColor ? backgroundColor : 'rgba(156, 156, 156, .10)'};
.circle{
  display: block;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-image: ${({ circleWidth, background }) => background ?`conic-gradient(${background} ${circleWidth}deg,transparent 0 235deg,transparent 0)`: '136px'};
  font-family: Rubik-Medium;
  font-size: 16px;
  line-height: 11px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  color: ${({ color}) =>  color ? color : 'white'};
}
}
.option{
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color:#005C87;
  display:flex;
  justify-content:Center;
  text-align: ${({ align}) =>  align ? "center" : 'center'};
  padding: ${({ padding }) => padding ? padding : '20px 0px 0px 0px'};
  text-transform: capitalize;
}
`;

const MultipleAnswerCard = styled.div`
width: ${({ width }) => width ? width : '280px'};
border: 1px solid #005c8799;
border-radius:6px;
background: white;
height:80p;
padding:15px;
margin-right: ${({ margin }) => margin ? margin : '15px'};
display: flex;
flex-wrap: wrap;
margin-bottom:10px;
@media only screen and (max-width: 1370px){
  width: 260px;
}
@media only screen and (max-width: 1297px){
  width: 250px;
}
.option{
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color:#005C87;
  padding-bottom: 5px;
  text-transform: capitalize;
}
.bar{
  width: 100%;
  margin: auto;
  display: flex;
  margin-bottom: 0px;
}
`;

const DropdownWrapper = styled.div`
  display:flex;
  margin:auto;
  margin-right:15px;
  .label{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 92, 135, 0.6);
    padding-right:10px;
    align-items: center;
    display: flex;
  }
`;

export { CategoryCard, CategoryCardWrapper, AssesmentWrapper, InsightReport, CategoryScoreCard, QuestionsCard, AnswerWrapper, AnswerCard, CircleNew, CircularGraph, MultipleAnswerCard, DropdownWrapper }